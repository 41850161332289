import React, { useState, useEffect, Dispatch } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./css/style.css";
import "./css/satoshi.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./services/StateManagement/Store";
import RealmSelection from "./common/RealmSelection";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import {
  SetKeycloakRealm,
  checkRealmExists,
  initOptions,
} from "./services/KeyCloak/KeyCloak";
import { setAuthState } from "./services/StateManagement/AuthSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import { error_message } from "./constants/Errors";
import messages from "./constants/Messages";
import { LOGIN_STATES } from "./constants/Enums";

const Main = () => {
  const dispatch: Dispatch<UnknownAction> = useDispatch();

  const adminRealm: string = import.meta.env.VITE_ADMIN_REALM;
  const appName: string = import.meta.env.VITE_APP_NAME;
  const adminHomePage: string = import.meta.env.VITE_APP_ADMIN_HOME_PAGE;
  const redirectRealm: boolean = import.meta.env.VITE_REALM_REDIRECT === "true";

  document.title = appName;

  const [realm, setRealm] = useState<string | null>(
    localStorage.getItem("realm"),
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loginState, setLoginState] = useState<string | null>(
    localStorage.getItem("logInState") === null
      ? "start"
      : localStorage.getItem("logInState"),
  );

  useEffect(() => {
    const previousState: string | null = localStorage.getItem("previousState");
    const logInState: string | null = localStorage.getItem("logInState");

    if (previousState == "logging_out") {
      localStorage.setItem("previousState", LOGIN_STATES.START);
    }

    if (previousState == "start" && logInState != LOGIN_STATES.LOGGED_IN) {
      localStorage.setItem("logInState", LOGIN_STATES.START);
    }
  }, []);

  useEffect(() => {
    const storedRealm: string | null = localStorage.getItem("realm");
    if (storedRealm != adminRealm) {
      setRealm(storedRealm);
    } else if (
      loginState != LOGIN_STATES.LOGGING_IN &&
      loginState != LOGIN_STATES.LOGGED_IN
    ) {
      setRealm("");
    }
  }, []);

  const handleRealmSelection: (selectedRealm: any) => Promise<void> = async (
    selectedRealm,
  ) => {
    if (selectedRealm != "" && selectedRealm != null) {
      const realmExists: boolean = await checkRealmExists(selectedRealm);

      if (selectedRealm === adminRealm && redirectRealm) {
        window.location.href = adminHomePage; // redirect the user to the admin login if they enter the admin realm
        return;
      }

      if (realmExists) {
        localStorage.setItem("previousState", loginState);
        localStorage.setItem("logInState", LOGIN_STATES.LOGGING_IN);
        setLoginState(localStorage.getItem("logInState"));
        localStorage.setItem("realm", selectedRealm);
        setRealm(selectedRealm);
        setLoading(true);
      } else {
        setError(error_message.tenant.realm_not_found(selectedRealm));
        return;
      }
    } else {
      setError(`テナントIDを入力してくだい.`);
      return;
    }
  };

  const handleSelectAdminRealm = () => {
    const previousState: string | null = localStorage.getItem("logInState");
    localStorage.setItem("previousState", loginState);
    localStorage.setItem("logInState", LOGIN_STATES.LOGGING_IN);
    setLoginState(localStorage.getItem("logInState"));
    localStorage.setItem("realm", adminRealm);
    setRealm(adminRealm);
    setLoading(true);
  };

  const handleKeycloakEvent = (event: string) => {
    switch (event) {
      case "onAuthSuccess":
        dispatch(setAuthState(true));
        localStorage.setItem("previousState", LOGIN_STATES.LOGGING_IN);
        localStorage.setItem("logInState", LOGIN_STATES.LOGGED_IN);
        setLoading(false);
        break;

      case "onAuthLogout":
        dispatch(setAuthState(false));
        localStorage.setItem("previousState", LOGIN_STATES.LOGGED_IN);
        localStorage.setItem("logInState", LOGIN_STATES.LOGGING_OUT);
        setLoginState(LOGIN_STATES.LOGGING_OUT);
        break;

      case "onAuthRefreshSuccess":
        console.info(messages.keycloak.token_authed);

        break;

      case "onTokenExpired":
        console.warn(error_message.keycloak.token_expired);
        break;

      default:
        break;
    }
  };

  if (
    loginState == LOGIN_STATES.START ||
    loginState == LOGIN_STATES.LOGGING_OUT
  ) {
    return (
      <RealmSelection
        onSelectRealm={handleRealmSelection}
        onSelectAdminRealm={handleSelectAdminRealm}
        loading={loading}
        error={error}
        selectedRealm={realm}
      />
    );
  }

  return (
    <ReactKeycloakProvider
      authClient={SetKeycloakRealm(realm)}
      initOptions={initOptions}
      onEvent={handleKeycloakEvent}
    >
      <App />
    </ReactKeycloakProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  //<React.StrictMode>
  <Router>
    <Provider store={store}>
      <Main />
    </Provider>
  </Router>,
  // </React.StrictMode>
);
