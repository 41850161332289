import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingIcon from "../../common/LoadingIcon";
import { TENANT } from "../../types/tenant";
import { error_message } from "../../constants/Errors";
import { CONTRACT_STATUS } from "../../constants/Enums";

const TenantsTables = ({
  onTenantSelect,
  onDeselectAll,
  tenantInformation,
  loading,
  allSelected,
  setAllSelected,
}) => {
  const [selectedTenants, setSelectedTenants] = useState<TENANT[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [tenantData, setTenantData] = useState<TENANT[]>();
  const [currentTenants, setCurrentTenants] =
    useState<TENANT[]>(tenantInformation);

  const getDisplayStatus = (status) => {
    return status === CONTRACT_STATUS.ACTIVE
      ? "契約中"
      : status === CONTRACT_STATUS.INACTIVE
      ? "未契約"
      : status === CONTRACT_STATUS.EXPIRED
      ? "契約終了"
      : "不明";
  };

  useEffect(() => {
    setCurrentTenants(tenantInformation);
  }, [tenantInformation]);

  useEffect(() => {
    setSelectAll(allSelected);
  }, [allSelected]);

  const handleTenantSelect = (tenantId) => {
    setSelectedTenants((prevSelected) => {
      if (prevSelected.includes(tenantId)) {
        return prevSelected.filter((id) => id !== tenantId);
      } else {
        return [...prevSelected, tenantId];
      }
    });
    onTenantSelect(tenantId);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setAllSelected(!selectAll);
    if (!selectAll) {
      const allTenantIds = currentTenants.map((tenant) => tenant.id);
      allTenantIds.forEach((tenantId) => onTenantSelect(tenantId, true));
      setSelectedTenants(allTenantIds);
    } else {
      setSelectedTenants([]);
      onDeselectAll();
    }
  };

  const validateEndDate = (endDate) => {
    const year = new Date(endDate).getFullYear();
    const nullOrLess = year < 2999 || null;
    return nullOrLess;
  };

  return (
    <div className="w-full">
      <div className="border border-text-field-border rounded-2xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full table-fixed mayo-card-header rounded-2xl">
            <thead className="border-b border-text-field-border">
              <tr>
                <th className="w-16 p-4 text-center">
                  <input
                    type="checkbox"
                    className="w-4 h-4"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="p-5 text-left label-light-blue min-w-[250px] md:w-40">
                  企業名
                </th>
                <th className="text-left label-light-blue min-w-[150px] md:w-40">
                  アプリ
                </th>
                <th className="text-left label-light-blue min-w-[250px] md:w-60">
                  契約期間
                </th>
                <th className="text-left label-light-blue min-w-[150px] lg:w-48">
                  利用者数
                </th>
                <th className="text-left label-light-blue min-w-[150px] lg:w-48">
                  ライセンス数
                </th>
                <th className="text-left label-light-blue min-w-[250px] lg:w-48">
                  先月確定請求額
                </th>
              </tr>
            </thead>
            <tbody className="mayo-card-body divide-y divide-text-field-border rounded-2xl">
              {currentTenants?.length === 0 ? (
                loading ? (
                  <LoadingIcon />
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center justify-center py-4">
                      {error_message.tenant.no_data}
                    </td>
                  </tr>
                )
              ) : (
                currentTenants?.map((tenant) => (
                  <tr key={tenant.id}>
                    <td className="p-4 text-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={selectedTenants.includes(tenant.id)}
                        onChange={() => handleTenantSelect(tenant.id)}
                      />
                    </td>
                    <td className="py-5 flex items-center gap-2.5 min-w-[150px]">
                      <div className="flex flex-col justify-center">
                        <Link to={`/company/${tenant.code}/manage`}>
                          <div>{tenant.code}</div>
                          <div className="text-xl text-bold hover:opacity-70 underline">
                            {tenant.name_kana}
                          </div>
                          <div>{tenant.name}</div>
                        </Link>
                      </div>
                    </td>
                    <td className="py-4 text-gray-900 min-w-[150px]">
                      {tenant?.contracts?.length > 0 ? (
                        <div className="flex flex-col gap-1">
                          {tenant.contracts.map((contract, index) => (
                            <div key={index} className="p-1">
                              <div>{contract.app_name}</div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>{getDisplayStatus(CONTRACT_STATUS.INACTIVE)}</div>
                      )}
                    </td>
                    <td className="py-4 text-gray-900 min-w-[250px] md:w-60">
                      {tenant?.contracts?.length > 0 ? (
                        <div className="flex flex-col gap-2">
                          {tenant.contracts.map((contract, index) =>
                            contract.status === CONTRACT_STATUS.ACTIVE ? (
                              <div key={index} className="p-1">
                                <div>{`${contract.start_date} ~ ${
                                  validateEndDate(contract.end_date)
                                    ? ""
                                    : contract.end_date
                                }`}</div>
                              </div>
                            ) : contract.status === CONTRACT_STATUS.EXPIRED ? (
                              <div className="text-yellow-500">
                                <p>
                                  {getDisplayStatus(CONTRACT_STATUS.EXPIRED)}{" "}
                                  <span className="text-sm ml-2">
                                    終了日：{contract.end_date}
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <div className="text-red-500">
                                <p>
                                  {getDisplayStatus(CONTRACT_STATUS.INACTIVE)}
                                </p>
                              </div>
                            ),
                          )}
                        </div>
                      ) : (
                        <div className="text-red-500">
                          {getDisplayStatus(CONTRACT_STATUS.INACTIVE)}
                        </div>
                      )}
                    </td>
                    <td className="py-4 text-gray-900 min-w-[150px] lg:w-48">
                      {tenant?.contracts?.length > 0 ? (
                        <div className="flex flex-col gap-2">
                          {tenant.contracts.map((contract, index) =>
                            contract.status === CONTRACT_STATUS.ACTIVE ||
                            contract.status === CONTRACT_STATUS.EXPIRED ? (
                              <div
                                key={index}
                                className={`p-1 ${
                                  contract.status === CONTRACT_STATUS.EXPIRED
                                    ? "text-yellow-500"
                                    : (contract.current_users || 0) >=
                                      (contract.contract_count || 0)
                                    ? "text-red-400"
                                    : (contract.current_users || 0) >=
                                      (contract.contract_count || 0) * 0.65
                                    ? "text-orange-400"
                                    : "text-green-400"
                                }`}
                              >
                                <div>{contract.current_users || "0"}</div>
                              </div>
                            ) : (
                              <div className="text-red-500">
                                {getDisplayStatus(CONTRACT_STATUS.INACTIVE)}
                              </div>
                            ),
                          )}
                        </div>
                      ) : (
                        <div className="text-red-500">
                          {getDisplayStatus(CONTRACT_STATUS.INACTIVE)}
                        </div>
                      )}
                    </td>
                    <td className="py-4 text-gray-900 min-w-[150px] lg:w-48">
                      {tenant?.contracts?.length > 0 ? (
                        <div className="flex flex-col gap-2">
                          {tenant.contracts.map((contract, index) =>
                            contract.status === CONTRACT_STATUS.ACTIVE ||
                            contract.status === CONTRACT_STATUS.EXPIRED ? (
                              <div
                                key={index}
                                className={`p-1 ${
                                  contract.status === CONTRACT_STATUS.EXPIRED
                                    ? "text-yellow-500"
                                    : (contract.current_users || 0) >=
                                      (contract.contract_count || 0)
                                    ? "text-red-400"
                                    : (contract.current_users || 0) >=
                                      (contract.contract_count || 0) * 0.65
                                    ? "text-orange-400"
                                    : "text-green-400"
                                }`}
                              >
                                <div>{contract.contract_count || "0"}</div>
                              </div>
                            ) : (
                              <div className="text-red-500">
                                {getDisplayStatus(CONTRACT_STATUS.INACTIVE)}
                              </div>
                            ),
                          )}
                        </div>
                      ) : (
                        <div className="text-red-500">
                          {CONTRACT_STATUS.INACTIVE}
                        </div>
                      )}
                    </td>
                    <td className="py-4 text-gray-900 min-w-[150px] lg:w-48">
                      {tenant?.contracts?.length > 0 ? (
                        <div className="flex flex-col gap-2">
                          {tenant.contracts.map((contract, index) =>
                            contract.status === CONTRACT_STATUS.ACTIVE ||
                            contract.status === CONTRACT_STATUS.EXPIRED ? (
                              <div
                                key={index}
                                className={`p-1 ${
                                  contract.status === CONTRACT_STATUS.EXPIRED
                                    ? "text-yellow-500"
                                    : ""
                                }`}
                              >
                                <div>{`${
                                  contract.unit_price * contract.contract_count
                                } 円`}</div>
                                <div className="text-xs">{`単価 (${
                                  contract.unit_price
                                } 円) x ライセンス数 ${
                                  contract.contract_count || 0
                                }`}</div>
                              </div>
                            ) : (
                              <div className="text-red-500">
                                {getDisplayStatus(CONTRACT_STATUS.INACTIVE)}
                              </div>
                            ),
                          )}
                        </div>
                      ) : (
                        <div className="text-red-500">
                          {getDisplayStatus(CONTRACT_STATUS.INACTIVE)}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TenantsTables;
