// map containing the error values returned in english from the API / Keycloak
const errorTranslations = {
  "Invalid Token: Token decoding failed: The token has expired":
    "トークンの期限が切れました。",
  "app not found": "アプリ情報は正しくありません。",
  "User exists with same email":
    "同じメールアドレスのユーザーが既に存在します。",
  "User not found": "ユーザがKeycloakに存在していません。",
  "User not found with id": "ユーザが存在していません。",
  "User couldn't be deleted": "ユーザがKeycloakに存在していません。",
  "user with the same phone number exists in database":
    "同じ電話番号のユーザーが既に存在します",
  "User exists with same username":
    "同じログインIDのユーザーが既に存在します。",
  "Missing parameter: login_id and login_password are required":
    "ログインIDとパスワードは必須です。",
  "error-invalid-email": ({ field, params }) =>
    `無効な${field}アドレス: ${params[1]}。有効なメールアドレスを入力してください。`,
  "Kana name カナはカタカナのみ使用できます。":
    "氏名カナはカタカナのみ使用可能です。",
  "First kana name かな名はひらがなのみ使用できます。":
    "名前はひらがなのみ使用可能です。",
  "First kana name can only contain hiragana characters.":
    "名前は英数字、ハイフンのみ使用可能です。",
  "Last kana name can only contain hiragana characters.":
    "名前は英数字、ハイフンのみ使用可能です。",
  "First furigana name can only contain hiragana characters.":
    "ふりがな名はひらがなのみ使用可能です。",
  "Last furigana name can only contain hiragana characters.":
    "ふりがな名はひらがなのみ使用可能です。",
  "Name can only contain alphanumeric characters, japanese characters, and hyphens.":
    "名前は英数字、日本語文字、ハイフンのみ使用できます。",
  "Personal tel invalid phone number format":
    "有効な電話番号形式(10, 11数字のみ)で入力してください。",
  "Company tel invalid phone number format":
    "有効な電話番号形式(10, 11数字のみ)で入力してください。",
  "Company home page is invalid": "有効なURLを入力してください。",
  "Personal tel can't be blank": "電話番号を入力してください。",
  "First name name is blank": "名前を入力してください。",
  "Validation failed: Name can't be blank": "名前を入力してください。",
  "Validation failed: Kana name can't be blank":
    "名前（カナ）を入力してください。",
  "Validation failed: Postcode can't be blank": "郵便番号を入力してください。",
  "Validation failed: Address can't be blank": "住所を入力してください。",
  "Validation failed: Url can't be blank": "URLを入力してください。",
  "something happened. When create Yobery BROADCAST-GROUP":
    "同じ名称のグループが既に存在します。",
  "An unexpected error occurred while updating password":
    "パスワードの更新中に予期しないエラーが発生しました。",
  "Couldn't find Role with 'id'=1": "該当するロールが見つかりませんでした。",
  "no available licenses for YOBELY": "YOBELYのライセンスは利用できません。",
  "no available licenses for KAKELY": "KAKELYのライセンスは利用できません。",
  "no available licenses for WorkJoy": "WorkJoyのライセンスは利用できません。",
  "unable to find contract for app YOBELY": "YOBELYは未契約です。",
  "unable to find contract for app KAKELY": "KAKELYは未契約です。",
  "unable to find contract for app WorkJoy": "WorkJoyは未契約です。",
  "license status for YOBELY is no contract": "YOBELYは未契約です。",
  "license status for KAKELY is no contract": "KAKELYのライセンスは未契約です",
  "license status for WorkJoy is no contract": "WorkJoyは未契約です。",
  "cannot register department without position":
    "役職なしでは部署を登録できません",
  "cannot set KAKELY license number lower than current users":
    "ご利用中のKAKELYユーザー数より少ないライセンス数に変更することはできません",
  "cannot set YOBELY license number lower than current users":
    "ご利用中のYOBELYユーザー数より少ないライセンス数に変更することはできません",
  "cannot set WorkJoy license number lower than current users":
    "ご利用中のWorkJoyユーザー数より少ないライセンス数に変更することはできません",
  "HTTP 405 Method Not Allowed": "ユーザがKeycloakに存在していません。",
  "Validation failed: First kana name フリガナは英字とハイフンのみ使用できます":
    "名前は英数字、ハイフンのみ使用可能です。",
  "Validation failed: Last kana name フリガナは英字とハイフンのみ使用できます":
    "名前は英数字、ハイフンのみ使用可能です。",
  "Validation failed: First furigana name かな名はひらがなのみ使用できます。":
    "名前はひらがなのみ使用可能です。",
  "Validation failed: Last furigana name かな名はひらがなのみ使用できます。":
    "名前はひらがなのみ使用可能です。",
  "Validation failed: Personal tel invalid phone number format":
    "有効な電話番号形式(10, 11数字のみ)で入力してください。",
  "cannot delete own user": "自分自身のユーザーは削除できません",
  "Personal tel user exists with same phone number":
    "同じ電話番号のユーザーが既に存在します。",
  "Validation failed: Personal tel user exists with same phone number":
    "同じ電話番号のユーザーが既に存在します。",
  "insufficient access for resource role":
    "管理者ロールに変更する権限が不足しています。",
  "cannot update own account attribute: role":
    "自分自身のロールを更新することはできません。",
  "cannot create resource with administrator permissions":
    "管理者権限を持つリソースを作成することはできません。",
  'an error occurred: Bad request: {"error":"invalidPasswordMinSpecialCharsMessage","error_description":"Invalid password: must contain at least 1 special characters."}':
    "無効なパスワード：少なくとも1つの特殊文字を含める必要があります。",
  'an error occurred: Bad request: {"error":"invalidPasswordMinLengthMessage","error_description":"Invalid password: minimum length 8."}':
    "無効なパスワード：最低でも8文字以上である必要があります。",
  'an error occurred: Bad request: {"error":"invalidPasswordMinUpperCaseCharsMessage","error_description":"Invalid password: must contain at least 1 upper case characters."}':
    "無効なパスワード：少なくとも1つの大文字を含める必要があります。",
  'an error occurred: Bad request: {"error":"invalidPasswordMinDigitsMessage","error_description":"Invalid password: must contain at least 1 numerical digits."}':
    "無効なパスワード：少なくとも1つの数字を含める必要があります。",
  'an error occurred: Bad request: {"error":"invalidPasswordMinLowerCaseCharsMessage","error_description":"Invalid password: must contain at least 1 lower case characters."}':
    "無効なパスワード：少なくとも1つの小文字を含める必要があります。",
  invalidPasswordMinSpecialCharsMessage:
    "無効なパスワード：少なくとも1つの特殊文字を含める必要があります。",
  invalidPasswordMinUpperCaseCharsMessage:
    "無効なパスワード：少なくとも1つの大文字を含める必要があります。",
  invalidPasswordMinLowerCaseCharsMessage:
    "無効なパスワード：少なくとも1つの小文字を含める必要があります。",
  invalidPasswordMinDigitsMessage:
    "無効なパスワード：少なくとも1つの数字を含める必要があります。",
  invalidPasswordMinLengthMessage:
    "無効なパスワード：最低でも8文字以上である必要があります。",
  "Validation failed: Email can't be blank":
    "メールアドレスを入力してください。",
  "Validation failed: Email is not a valid email":
    "有効なメールアドレスを入力してください。",
  "email is required for administrator users":
    "管理者ユーザーにはメールアドレスが必要です。",
  "Validation failed: First furigana name can only contain hiragana characters.":
    "ふりがな名はひらがなのみ使用可能です。",
  "Validation failed: Personal tel is blank": "電話番号を入力してください。",
  "Personal tel is blank": "電話番号を入力してください。",
  "Last furigana name can only contain hiragana characters":
    "ふりがな名はひらがなのみ使用可能です。",
  'an error occurred: Bad request: {"error":"invalidPasswordHistoryMessage","error_description":"Invalid password: must not be equal to any of last 3 passwords."}':
    "無効なパスワードです: 過去3回のパスワードと同じであってはなりません。",
  "Couldn't find User without an ID": "IDを入力してください。",
  "tenant does not match user registered tenant":
    "登録されたテナントがユーザーのテナントと一致しません",
  "cannot alter values of user outside own tenant":
    "登録されたテナントがユーザーのテナントと一致しません",
  "contact history not found": "連絡履歴が見つかりません。",
  "Login can only contain letters, numbers, dots, underscores, and hyphens":
    "ログインには、文字、数字、ドット、アンダースコア、ハイフンのみを使用できます。",
  "Invalid password: must not be equal to any of last 3 passwords.":
    "無効なパスワードです: 過去3回のパスワードと同じであってはなりません。",
  "Invalid password: must contain at least 1 special characters.":
    "無効なパスワード：少なくとも1つの特殊文字を含める必要があります。",
  "Invalid password: minimum length 8.":
    "無効なパスワード：最低でも8文字以上である必要があります。",
  "Invalid password: must contain at least 1 upper case characters.":
    "無効なパスワード：少なくとも1つの大文字を含める必要があります。",
  "Invalid password: must contain at least 1 numerical digits.":
    "無効なパスワード：少なくとも1つの数字を含める必要があります。",
  "Invalid password: must contain at least 1 lower case characters.":
    "無効なパスワード：少なくとも1つの小文字を含める必要があります。",
  "Couldn't find App without an ID": "アプリが見つかれませんでした",
  "login_id and login_password are required":
    "ログインIDまたはパスワードを入力してください。",
  "failed to add user to job pool - delete flag set incorrectly":
    "削除フラグが正しく設定されていません",
  "tenant not found": "テナントが見つかれませんでした。",
  "Invalid Token: The token has expired": "トークンの期限が切れました。",
  "Validation failed: Last name can only contain alphanumeric characters, japanese characters, and hyphens.":
    "名前は英数字、日本語文字、ハイフンのみ使用できます。",
  "tenant_department not found": "部署が見つかれませんでした。",
  "First name can only contain alphanumeric characters, japanese characters, and hyphens":
    "名前は英数字、日本語文字、ハイフンのみ使用できます。",
  "failed to create user: First name can only contain alphanumeric characters, japanese characters, and hyphens.":
    "名前は英数字、日本語文字、ハイフンのみ使用できます。",
};

export default errorTranslations;
